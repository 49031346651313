import { connect } from 'react-redux';
import { getPosts as _getPosts } from '../../state/actions';
import { PostPreviewList } from '../../components/PostPreviewList';

var mapStateToProps = function mapStateToProps(state) {
  return {
    posts: Object.values(state.provider.posts)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getPosts: function getPosts(providerServiceId) {
      return dispatch(_getPosts(providerServiceId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostPreviewList);