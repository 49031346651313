import { connect } from 'react-redux';
import { getServiceReviews as getServiceReviewsAction } from '../../state/actions';
import { ReviewList } from './ReviewList';

var mapStateToProps = function mapStateToProps(state) {
  return {
    serviceReviews: state.reviews.serviceReviews
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getServiceReviews: function getServiceReviews(serviceId) {
      return dispatch(getServiceReviewsAction(serviceId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewList);