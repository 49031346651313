import { connect } from 'react-redux';
import cookie from 'js-cookie';
import { getSchedule as _getSchedule, createSession as createSessionAction } from '../../state/actions';
import { Schedule } from '../../components/ProviderService/Schedule';
import { CookieName } from '../../utils/constants';

var mapStateToProps = function mapStateToProps(state) {
  return {
    currentUser: state.auth.users.current,
    loginRequestSent: state.auth.loginRequestSent,
    accessToken: cookie.get(CookieName.ACCESS_TOKEN),
    schedules: Object.values(state.provider.schedules)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getSchedule: function getSchedule(activityPayload) {
      return dispatch(_getSchedule(activityPayload));
    },
    createSession: function createSession(payload) {
      return dispatch(createSessionAction(payload));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);