import cookie from 'js-cookie';
import { CookieName } from './constants';
export var setServicePasswordCookie = function setServicePasswordCookie(input) {
  if (false) return;
  var str = JSON.stringify(input);
  cookie.set(CookieName.SERVICE_PASSWORDS, str, {
    expires: 1
  });
};
export var getServicePasswordCookie = function getServicePasswordCookie() {
  if (false) return;
  var str = cookie.get(CookieName.SERVICE_PASSWORDS);
  if (!str) return {};
  return JSON.parse(str);
};